import React, { useState } from 'react';
import SearchAll from '../../SearchAll';

const AppSearch = ({ activeApp, setActiveApp, applications, handleChangeApplication }) => {
  

  return (
    <>
      <div className="appscroll mt-4">
        <p className="apphead">Applications</p>
        <div className="applistparent">

          <div className="applist">
            <div>
              <span
                className={activeApp === 'Applications' ? 'active' : ''}
                onClick={() => setActiveApp('Applications')}
              >
                Applications
              </span>
            </div>

            {applications?.filter((app) => app.resourceType[0]?.id).map((item, index) => {
              const resourceType = item.resourceType.filter((i) => i.id === item.resourceTypeId)[0];
              if (!resourceType) return null;
              return (
                <div key={index}>
                  <span
                    className={activeApp === resourceType.name ? 'active' : ''}
                    onClick={() => {
                      setActiveApp(resourceType.name);
                      handleChangeApplication(item?.resourceTypeId, resourceType.type);
                    }}
                  >
                    {resourceType.name}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default AppSearch;
