import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, Link } from 'react-router-dom';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import axios from 'axios';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import { useSelector } from 'react-redux';
import TeamGroupDetail from './TeamGroupDetail';
import AssignedResources from './AssignedResources/AssignedResources';

import SettingTeamManagement from './SettingTeamManagement';
import TeamManagementLoadComp from '../LoadingComponents/TeamManagementLoadComp';

const UserResources = () => {

    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [userDetail, setUserDetail] = useState({});
    const configData = useSelector((state) => state.configSlice);
    const userData = useSelector((state) => state.userSlice);
    const permissions = userData.user.permissions;
    const [activeTab, setActiveTab] = useState('resources');
    const { uuid } = useParams();

    const fetchUserDetail = async () => {
        try {
            const resData = await axios.get(`${configData.config.UNO_URL}/api/user/details/${uuid}`, {
                headers: {
                    Authorization: `Bearer ${userData?.token}`
                }
            });

            setUserDetail({ ...resData?.data?.data.user });
            setLoading(false);
        } catch (error) {

            console.log(error);
        }
    };

    useEffect(() => {
        if (uuid.length>0) fetchUserDetail();
    }, [uuid])
    const handleTabChange = (newTab) => {
        setActiveTab(newTab);
    };


    if (loading) {
        return <TeamManagementLoadComp />;
      }
    ;
    return (
        <>
            <section className="">
                <div className="row align-items-center"  style={{ minHeight:"42px" }}>
                    <div className="col pagetitletext">
                        {<span onClick={() => navigate(-1)} > <button className='back'> <KeyboardBackspaceOutlinedIcon /> </button> </span>}
                        <span>Team Management</span>
                    </div>
                    {activeTab === 'resources' ? (!permissions.includes("RESOURCE.ASSIGNMENT.ASSIGN") ? null : <div className="col text-end">
                        <button  onClick={() => navigate(`/dashboard/team-management/assign/resources/user/${uuid}`)} className="createbtn">Assign Resources</button>
                    </div>) : null}
                </div>
            </section>

            <TeamGroupDetail type="user" detail={userDetail} refresh={fetchUserDetail} permissions={permissions} />

            <div className="pt-3">

                <Tab.Container id="left-tabs-example" defaultActiveKey="resources" onSelect={handleTabChange}>
                    <div className="row">
                        <div className="col-md-8">
                            <Nav variant="pills" className='tabsproduct'>
                                {!permissions.includes("RESOURCE.ASSIGNMENT.VIEW") ? null :
                                    <Nav.Item>
                                        <Nav.Link eventKey="resources">Resources</Nav.Link>
                                    </Nav.Item>
                                }
                                {!permissions.includes("TEAM.USERS.SETTINGS") ? null :
                                    <Nav.Item>
                                        <Nav.Link eventKey="settings">Settings</Nav.Link>
                                    </Nav.Item>
                                }
                            </Nav>
                        </div>
                        <div className="col-md-4"/>

                        <Tab.Content>
                            {!permissions.includes("RESOURCE.ASSIGNMENT.VIEW") ? null :
                                <Tab.Pane eventKey="resources">
                                    <div className="row">
                                        <AssignedResources type={"user"} />
                                    </div>
                                </Tab.Pane>
                            }
                            {!permissions.includes("TEAM.USERS.SETTINGS") ? null :
                                <Tab.Pane eventKey="settings">
                                    <SettingTeamManagement type={'user'} id={uuid} showInherit={!(userDetail?.group?.uuid === userData.user.group.uuid)} />
                                </Tab.Pane>
                            }
                        </Tab.Content>
                    </div>
                </Tab.Container>

            </div>
        </>
    )
}

export default UserResources
